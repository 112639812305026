
.header{
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 10px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 9;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;;
}
.user{
    display: flex;
    align-items: center;
}
.user div{
    margin:0 10px

}

.menubar button{
    border: navajowhite;
    background: transparent;
    color: rgb(64,78,103);
    font-size: 1.2rem;
}


/* dropmenu */

/* nav {
    padding: 1px;
    text-align: center;
  }
  nav > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    background: #ddd;
    border-radius: 5px;
  }
  nav > ul > li {
    float: left;
    width: 100px;
    height: 30px;
    line-height: 30px;
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }
  nav > ul > li:hover {
    background: #d5d5d5;
  }
  
  ul.drop-menu {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    padding: 0;
  }
  ul.drop-menu li {
    background: #666;
    color: rgba(255, 255, 255, 0.7);
  }
  ul.drop-menu li:hover {
    background: #606060;
  }
  ul.drop-menu li:last-child {
    border-radius: 0px 0px 5px 5px;
  }
  
  ul.drop-menu li {
    display: none;
  }
  
  li:hover > ul.drop-menu li {
    display: block;
    /*menu 1*/
  /* } */
  
  /* li:hover > ul.drop-menu.menu-1 {
    perspective: 1000px;
  }
  li:hover > ul.drop-menu.menu-1 li {
    opacity: 0;
  }
  li:hover > ul.drop-menu.menu-1 li:nth-child(1) {
    animation-name: menu1;
    animation-duration: 300ms;
    animation-delay: -170ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  li:hover > ul.drop-menu.menu-1 li:nth-child(2) {
    animation-name: menu1;
    animation-duration: 300ms;
    animation-delay: -40ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  li:hover > ul.drop-menu.menu-1 li:nth-child(3) {
    animation-name: menu1;
    animation-duration: 300ms;
    animation-delay: 90ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  li:hover > ul.drop-menu.menu-1 li:nth-child(4) {
    animation-name: menu1;
    animation-duration: 300ms;
    animation-delay: 220ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  li:hover > ul.drop-menu.menu-1 li:nth-child(5) {
    animation-name: menu1;
    animation-duration: 300ms;
    animation-delay: 350ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  li:hover > ul.drop-menu.menu-1 li:nth-child(6) {
    animation-name: menu1;
    animation-duration: 300ms;
    animation-delay: 480ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  
  @keyframes menu1 {
    0% {
      opacity: 0;
      transform: rotateY(-90deg) translateY(30px);
    }
    100% {
      opacity: 1;
      transform: rotateY(0deg) translateY(0px);
    }
  }  */
 