.counter-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.counter-container{
    background: #a47ea6;
    width: 250px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    background: #222e3c;;
    border-radius: 10px;
    margin: 15px;
    padding: 15px;
    color: white;
}
.counter-container .counter{
    font-size: 1.4em;

}
.counter-container h3{
    text-transform: capitalize;
}
.counter-container .icondate{
    font-size:1,5em;
    font-weight:bold;
}
.chart{
    width: 250px;
    padding: 10px;
    background: #222e3c;;
    border-radius: 20px;
    margin: 20px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

/* @media only screen and (max-width: 600px) {
    body {
      background-color: lightblue;
    }
  } */

  @media (min-width: 991px) and (max-width: 1200px) {
    .chart{
        width: 200px;
     }
     .counter-container{
        width: 200px;
     }
   }