.userDetails_2{
    margin-bottom: 50px;
}
.main_container{
    margin-top: 20px;
}
.SearchTaple{
    display: flex;
    justify-content:space-around;
    align-items: center
}
.UserTapleDetails{
    background-color: #fff;
    padding: 10px;
    margin-top: 57px;

}.UsersTaple{
    margin-top:20px;
}

.UserTapleDetails_row_0{
    margin-bottom: 10px;
}
.UserTapleDetails_row_1 ,.UserTapleDetails_row_2 ,.UserTapleDetails_row_3{
    margin-bottom: 10px;
}

.UserTapleDetails_label{
    width: 100px;
    display: flex;
    align-items: center;
    text-align: center;
 }
.UserTapleDetails_label_api{
    font-weight: bold;
    font-size: 12px;
    border-bottom: 1px solid #ddd;
}
.last_bun{
    border: none;
    padding: 5px;
    background: #222e3c;
    color: #fff;
    border-radius: 5px;
}

