 
/* ======================== */
.main{
    display:flex;
    background-color:#F2F2F2;
}
.main-container-open{
    width: 100%;
    padding-left: 200px;
    transition:  all 0.2s ease-in-out;
}
.main-container-close{
    width: 100%;
    padding-left: 80px;
    transition:  all 0.2s ease-in-out;
}
.main-container-ar-close{
    transition:  all 0.2s ease-in-out;
    padding-right: 80px;
    width: 100%;
}

.main-containe-ar-open{
    padding-right: 200px;
    width: 100%;
    transition:  all 0.2s ease-in-out;
}

.main-outlet{
    min-height:calc(100vh - 100px);
}



@media only screen and (max-width: 768px) {

   .main-container-open{
     padding-left: 0;
 }
.main-containe-ar-open{
    padding-right: 0;
    width: 100%;
 }

}