.searchTaple_but{
    width: 120px;
    border: none;
    padding: 5px;
    text-transform: capitalize;
    background: #222e3c;
    color: #fff;
    border-radius: 6px;
    margin: 10px;

}
.section_2{
    display:flex;
    justify-content:space-around;
    align-items: center;
}
.section_2{
    padding-top: 7px;
}
.section_2 >div {
    display:flex;
}
.switch{
 margin: 0 10px;
}

.form{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 20px;

}

@media only screen and (max-width: 720.99px) {
    .section_1{
        display: flex;
        flex-direction: column;

    }
    .section_1 div{
        margin-bottom: 40px;
    }
}
