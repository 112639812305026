.add_btn{
  width: 120px;
  border: none;
  padding: 5px;
  text-transform: capitalize;
  background: #222e3c;
  color: #fff;
  border-radius: 6px;
  margin: 10px;

}

.row_1{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 5px;
    margin: 5px;
}
.section-1 span{
  font-size: 12px
}
.button-cover, .knobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.col-1-inputs{
  margin-bottom:30px
}
.PG2Md01Lb{
  display: flex;
  align-items: center;
}
.PG2Md01Lb span{
  margin-right:5px
}
.all_switchs{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
}
.all_switchs span{
  font-size:12px
}
.row-PGMd01Sw1{
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 20px;
}
.button
{
  
    position: relative;
    top: 50%;
    width: 250px;
    height: 50px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

.button.r, .button.r .layer
{
    /* border-radius: 100px; */
}

.button.b2
{
    border-radius: 2px;
}

.checkbox
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs
{
    z-index: 2;
}

.layer
{
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}
 
 
/* Button 3 */
#button-3 .knobs:before
{
   
    content: 'توصيل الي العمل';
    position: absolute;
    top: 5px;
    left: 4px;
    width: 111px;
    height: 40px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 2;
    padding: 9px 4px;
    background-color: #03A9F4;
    /* border-radius: 50%; */
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-3 .checkbox:active + .knobs:before
{
    width: 46px;
    /* border-radius: 100px; */
}

#button-3 .checkbox:checked:active + .knobs:before
{
    margin-left: -26px;
}

#button-3 .checkbox:checked + .knobs:before
{
    content: 'توصيل الي المنزل';
    left: 134px;
    background-color: #f44336;
}

#button-3 .checkbox:checked ~ .layer
{
    background-color: #fcebeb;
}

  input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

label {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 20px;
	background: #0d6efd;
	display: block;
	border-radius: 100px;
	position: relative;
}

label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 10px;
	height: 10px;
	background: #fff;
	border-radius: 50px;
	transition: 0.3s;
}

input:checked + label {
	background:#0d6efd;
  ;
}

input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

label:active:after {
	width: 10px;
}

.resalt{
  width: 150px;
   border: 1px solid #ddd;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 10px;
}
.multiplied_mark{
  font-size:20px;
  margin:10px
}
.margin_4{
  margin-bottom:40px;
}
.col-1-inputs span{
  font-size: 12px;

}
.section-2-row2 span{
  font-size: 12px;
}
.section-2-row2-div1{
  align-items: center;

}
.section-2-row2-resalt{
  width: 46%;
  margin-top: 20px;
}

@media only screen and (max-width: 991.99px) {
 .row_2_col_1{
  flex-direction: column;
  margin-top: 40px;
 }
 .resalt{
  margin: 10px 0  !important;
  width: auto;

 }
 .multiplied_mark{
  margin: 0;
  text-align: center;

 }
 .margin_4{
  margin-bottom:20px;
}
 .row_checkbox{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 }
}