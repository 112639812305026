@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap');
.mb-2{
  position: relative;
}
.rtlDir{
  direction:rtl;
}
a{
  text-decoration: none;
}

/* scrollbar */
body::-webkit-scrollbar{
	width: 10px;
  }
 body::-webkit-scrollbar-track{
	 background:#eeeeeeba;
   }
body::-webkit-scrollbar-thumb{
	 background: #3A445C;
	 border-radius: 20px;
	 border: 1px solid #ddd;
   }
   tbody {
  font-size: 12px;
  text-align: center;
}

   :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40; 
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a:hover {
  color: unset ; 
  text-decoration: none;

}
a {
  color: unset;
  text-decoration: none;
}
 