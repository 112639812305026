.userDetails{
    margin-bottom: 50px;
}

.main_container{
    margin-top: 20px;
}
.SearchTaple{
    display: flex;
    justify-content:space-around;
    align-items: center
}
.UserTapleDetails{
    background-color: #fff;
    padding: 10px;
    margin-top: 57px;

}
.UsersTaple{
    margin-top:20px;
}
.UserTapleDetails_textarea{
    width: 100%;
}
.UserTapleDetails_textarea textarea{
    width: 100%;
    height: 100px;
    border-radius: 10px;
    outline: none;
    padding: 10px;
    font-size:1.2em
}
.UserTapleDetails_row_0{
    margin-bottom: 10px;
}
.UserTapleDetails_row_0 button{
    border: none;
    padding: 5px;
    background: #222e3c;
    color: #fff;
    border-radius: 5px;
}
.UserTapleDetails_label{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* justify-content: center; */
}
.UserTapleDetails_label_api{
    font-weight: bold;
    font-size: 12px;
}
.UserTapleDetails_row_1 ,.UserTapleDetails_row_2 ,.UserTapleDetails_row_3{
    margin-bottom: 10px;
}
.UserTapleDetails_row_1{

}

.multiplie{
    display: flex;
    justify-content: center;
    align-items: center;
}
