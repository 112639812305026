 .logo{
   background: #fff;
   text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;

 }
 .sidebar{
    position:fixed;
    height: 100%;
    transition: all 0.4s ease-in-out;
 }
 .sidebar-container::-webkit-scrollbar{
	width: 10px;
  }
 .sidebar-container::-webkit-scrollbar-track{
	 background:#eeeeeeba;
   }
.sidebar-container::-webkit-scrollbar-thumb{
	 background: #3A445C;
	 border-radius: 20px;
	 border: 1px solid #ddd;
   }
 /* .bOQEtf{
   width: auto !important;
   min-width: none !important;

 } */
 .ffSMgA{
  color: #DCDCDC !important;
 }
 .lkTtqM:hover {
  background-color: #2a9d8f !important;
  color: #fff !important;

}
/* .lkTtqM:hover {
  background-color: unset !important;
} */
.active{
  color: #2a9d8f ;
  font-weight: bold;
  font-size: 1.2rem;


}
.gnJkew{
  color: rgb(64,78,103) !important;
 }
a:hover {
  color: unset !important ; 
  text-decoration: none !important;

}
a {
  color: unset !important;
  text-decoration: none !important;
}

.sidbar-fixed-sidebar-ar::-webkit-scrollbar{
	width: 10px;
  }
  .sidbar-fixed-sidebar-ar::-webkit-scrollbar-track{
	 background:#eeeeeeba;
   }
   .sidbar-fixed-sidebar-ar::-webkit-scrollbar-thumb{
	 background: #3A445C;
	 border-radius: 20px;
	 border: 1px solid #ddd;
   }
.active{
  color:#fff
}

   /* test */
/*     
  .menu-container{
    width: 70px;
    display:inline-block;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 800px;
      perspective: 800px;
    float: left;
  
  }
  .menu{
    height: 60px;
    line-height: 60px;
  }
  .menu .icon{
    padding: 20px 20px;
    font-size: 22px;
    color: #6295c9;
    background: white;
    border-right: 0.5px solid lightgrey;
  }
  .menu .text{
    width: 180px;
    position:absolute; 
    transition: 0.7s;
    transform-origin: 0px 0px;
    transform: rotateY(90deg);
    padding: 0px 20px;
    backface-visibility: hidden;
    background: white;
  }
  .menu-container:hover .text{
    transform: rotateY(0deg);
  }
  .fa{
    width: 20px;
  }
  .menu a{
    text-decoration: none;
    color: #333;
  }
  .menu .text:hover,.menu .text:focus{
    background: #6295c9;
    color: #fff;
  }
  .content{
    margin-left: 70px;
    width:100%;
    text-align:center;
  } */